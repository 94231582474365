.AlterarSenhaField .p-password {
    width: 100% !important;
}

.AlterarSenhaField .p-password-input {
    height: 100% !important;
    width: calc(100% - 2rem); /* Ajusta a largura do input */
    text-align: center;
}

.AlterarSenhaField .p-password .p-password-toggle-icon {
    right: 0.5rem; /* Ajusta a posição do ícone */
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%);
    color: #24345a;
}

.AlterarSenhaField .p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
    color: #24345a;
    top: 0.80rem;
}