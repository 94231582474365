.PrintProntuario table {
    page-break-after: auto
}

.PrintProntuario thead {
    /* background-color: gray; */
    height: 240px;
    min-height: 450px;
    display: table-header-group;
}

.PronpsiLogo {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.PronpsiPanelHeader {
    border: 1px solid #24345a;
    padding: 1.25rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: #24345a;
    color: #fff !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}

.PronpsiPanelHeader label {
    font-weight: normal;
    color: white !important;
}

.PronpsiInformacoesPaciente {
    margin-left: 40px;
    margin-right: 40px;
    border: 1px solid #24345a;
}

.PrintProntuario tbody {
    display: table-row-group;
    width: 100%;
}

.PronpsiBodyInfoPacinte {
    text-align: left;
    margin-left: 57px;
    margin-right: 40px;
}

.PronpsiRegistroAtendimento {
    color: #24345a;
    margin-top: 12px;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 12px;
}

.PronpsiObservacoesEvolucao {
    color: #24345a;
    margin-top: 12px;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 12px;
}

.PrintProntuario tfoot {
    /* background-color: yellow; */
    height: 100px;
    min-height: 450px;
    display: table-footer-group;
}

.PronpsiFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    font-size: 10px;
    text-align: center;
    align-items: center;
}