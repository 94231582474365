.ObservacoesAgendaInputTextArea {
    font-size: 10px !important;
    color: white;
}

.FooterDialogAdicionarEvento {
    margin-top: 20px;
    display: flex !important;
    justify-content: space-between !important
}

.centralizado .p-inputtext {
    text-align: center !important;
  }