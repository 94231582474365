.Agenda {
    display: flex !important;
    justify-content: space-between !important
}

.AgendaTitle {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
}

.BodyAgenda {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
}

.DropdownUsuariosPsicologos {
    margin-bottom: 10px;
}

.rbc-month-row {
    flex-basis: auto !important;
    height: 100px !important;
    max-height: 800px;
}

.rbc-calendar {
    font-size: 12px !important;
}

.rbc-toolbar {
    font-size: 12px !important;
}

/* .rbc-time-content {
    max-height: 450px !important;
} */

.css-umzx0k-MuiClock-pin {
    background-color: #24345a !important;
}

.css-d0vs79-MuiClockPointer-root {
    background-color: #24345a !important;
}

.css-f53ilk-MuiClockPointer-thumb {
    background-color: #24345a !important;
    border: 16px solid #24345a !important
}

.css-12t0dn4-MuiClockPointer-thumb {
    background-color: #24345a !important;
    border: 16px solid #24345a !important;
    color: white
}

.css-knqc4i-MuiDialogActions-root>:not(style)~:not(style) {
    color: #24345a !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #24345a !important;
}