.RightInformations .p-inputtext {
    font-size: 10px !important;
    font-family: "Syncopate", sans-serif;
    background: #fff;
    color: #000;
    height: 38px !important;
    text-align: left;
}

.RightInformations .p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
    color: #24345a;
    top: 0.90rem;
}