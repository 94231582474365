.p-multiselect .p-inputtext {
    font-size: 12px !important;
}

div .p-checkbox .p-checkbox-box.p-highlight {
    font-size: 12px;
    border-color: #24345a !important;
    background-color: #24345a !important;
}

div .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem #222 !important;
    border-color: #24345a !important;
}

div .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #fff;
}

div .p-multiselect.p-multiselect-chip .p-multiselect-token {
    font-size: 12px;
    color: #24345a;
    background: #acbbd4;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    font-size: 12px !important;
    color: #24345a;
    background: #EEF2FF;
}

.CrudUsuarioSenha .p-password {
    width: 100% !important;
}

.CrudUsuarioSenha .p-password-input {
    width: calc(100% - 2rem); /* Ajusta a largura do input */
    text-align: center;
}

.CrudUsuarioSenha .p-password .p-password-toggle-icon {
    right: 0.5rem; /* Ajusta a posição do ícone */
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%);
    color: #24345a;
}

.CrudUsuarioSenha .p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
    color: #24345a;
    top: 0.80rem;
}

.CustomMultiSelect {
    font-size: 12px !important;
}