.p-multiselect .p-inputtext {
    font-size: 12px !important;
}

div .p-checkbox .p-checkbox-box.p-highlight{
    font-size: 12px;
    border-color: #24345a !important;
    background-color: #24345a !important;
}

div .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.2rem #222 !important;
    border-color: #24345a !important;
}

div .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #fff;
}

div .p-multiselect.p-multiselect-chip .p-multiselect-token {
    font-size: 12px;
    color: #24345a;
    background: #acbbd4;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    font-size: 12px !important;
    color: #24345a;
    background: #EEF2FF;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 20%;
    padding-left: 20%;
    text-align: center;
}

.CustomMultiSelect {
    font-size: 12px !important;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.75rem;
  color: #000;
}