* {
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  
  .App {
    text-align: center;
  }
  
  .page {
    width: 100%;
    height: 100%;
  }
  
  .content {
    width: calc(100% - 50px);
    margin-left: auto;
    padding: 15px;
    height: 100%;
    word-break: break-word;
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    width: 50px;
    height: 100%;
    background-color: #24345a;
    transition: width 0.3s ease;
    padding-top: 15px;
  }
  
  .sidebar .sidebar-position {
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 12px;
    text-align: left;
    font-weight: bold;
  }
  
  .sidebar .sidebar-position:hover {
    background-color: #24345a;
  }
  
  .sidebar svg {
    color: #7988ac;
    min-width: 25px;
  }
  
  .sidebar span {
    width: 0;
    height: 0;
    padding: 0 15px;
    transition: width 0.3s ease;
    color: #c9c9c9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  
  .sidebar--open {
    width: 250px;
    transition: width 0.3s ease;
  }
  
  .sidebar--open .sidebar-position span {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .trigger {
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px 15px;
  }

  .p-tooltip .p-tooltip-text {
    background: #7988ac;
    font-size: 10px;
  }