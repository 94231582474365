/*IMPORTANTE: core style class de edição dos campos do react quill, bem como da impressão*/

.ql-print {
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

.ql-editor-print {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.quill {
  position: relative;
}

.ql-toolbar.ql-snow {
  border: 1px solid white;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ql-container.ql-snow {
  position: relative;
  top: 40px; /* Altura da barra de ferramentas */
  left: 0;
  right: 0;
  height: 250px;
  background-color: white;
}
