.Medico {
    display: flex !important;
    justify-content: space-between !important
}

.Medico .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
}

.Body {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
}

.Body .NovoMedico{
    justify-content: left;
    display: flex !important;
    margin-bottom: 12px;
    /* justify-content: space-around !important */
}

.ActionItens{
    display: flex !important;
    justify-content: center !important
}