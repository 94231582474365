.RelatorioHistoricoAtendimentos h4 {
    font-size: 18px;
    color: #24345a;
    border-bottom: 2px solid #24345a;
    border-bottom-right-radius: 45px;
}

.FilterRelatorioHistoricoAtendimentos {
    width: 100%;
    display: flex !important;
    justify-content: left !important;
    margin-bottom: 40px;
}