.welcome-page {
  position: relative;
  background-image: url('/src/images/bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.heartbeat-logo {
  animation: heartbeat 1.5s ease-in-out infinite;
  display: block;
  margin-right: 20px;
}

@keyframes heartbeat {

  0%,
  100% {
    transform: scale(1);
  }

  25%,
  75% {
    transform: scale(1.1);
  }
}

.system-description {
  font-family: 'Sans Serif', sans-serif;
  font-size: 1.3rem;
  color: #24345a;
  margin-top: 0;
  margin-bottom: 0;
}

.mt-4 {
  margin-top: 24px;
}

.footer {
  font-family: 'Sans Serif', sans-serif;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0.45rem;
  color: #24345a;
  font-weight: bold;
}

.info-icon {
  width: 30px;
  font-size: 20px;
  color: #24345a;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.tooltip-box {
  visibility: visible;
  width: 600px;
  background-color: rgba(255, 255, 255, 0.95);
  color: #24345a;
  text-align: left;
  border-radius: 8px;
  padding: 15px;
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.tooltip-box ul {
  list-style-type: none;
  padding-left: 0;
}

.tooltip-box li {
  margin-bottom: 10px;
  font-size: 12px;
}

.show-more-btn {
  background-color: #24345a;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
}

.show-more-btn:hover {
  background-color: #456b87;
}

@media (max-width: 768px) {
  .tooltip-box {
    width: 90%;
    left: 5%;
    right: auto;
  }
}