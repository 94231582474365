.MeusPacientes {
    display: flex !important;
    justify-content: space-between !important;
    /* margin-left: 60px; */
}

.MeusPacientes .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    /* margin-right: 25px; */
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.ActionItens {
    display: flex !important;
    justify-content: center !important
}

.BodyMeusPacientes {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
}