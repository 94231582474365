
#login {
  font-family: 'Syncopate' !important;
}

.logo-container {
  opacity: 0;
  animation: fadeIn 2s ease forwards;
}

.login-fields-container {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  overflow-x: hidden;
  overflow-y: hidden;
}

.hide {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 599px) {
  .grid {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .p-card {
    width: 360px !important;
  }
  .login-fields-container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  h3 {
    display: none !important;
  }
  .logo-container {
    width: 360px !important;
    height: 90vh !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;

  }
}