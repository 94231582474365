.Prontuario {
    display: flex !important;
    justify-content: space-between !important
}

.ProntuarioFooter {
    margin-top: 20px;
    display: flex !important;
    justify-content: space-between !important
}

.Prontuario .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
}

.Body {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 25px;
}

.PanelHeader {
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: #24345a;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.PanelHeader label {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
    color: #fff;
}

.FilterProntuarioItens {
    width: 100%;
    display: flex !important;
    justify-content: left !important;
    margin-bottom: 40px;
}

.FilterProntuarioItens label {
    margin-right: 5px !important;
}