.DefaultButton button {
    font-family: system-ui, -apple-system, Arial, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue';
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
    color: white;
    border-radius: 70px;
    border: 3px solid;
    box-shadow: 10px 9px 10px rgb(44, 44, 44);
    transition-duration: 0.4s;
    cursor: pointer;
    overflow: hidden;
}

.DefaultButton button:hover {
    margin-right: 5px;
    margin-left: 5px;
    color: white;
    border-radius: 70px;
    border: 3px solid;
    box-shadow: 10px 9px 10px rgb(44, 44, 44);
}

.DefaultButton button:active {
    margin-right: 5px;
    margin-left: 5px;
    color: white;
    border-radius: 70px;
    border: 3px solid;
    box-shadow: 10px 2px 10px rgb(44, 44, 44);
    transform: translateY(4px);
}