.MeusAtendimentos {
    display: flex !important;
    justify-content: space-between !important;
    /* margin-left: 60px; */
}

.BodyMeusAtendimentos {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 25px;
}

.MeusAtendimentos .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    /* margin-right: 25px; */
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.FilterMeusAtendimentosItens {
    width: 100%;
    display: flex !important;
    justify-content: left !important;
    margin-bottom: 40px;
}

.FilterMeusAtendimentosItens label {
    margin-right: 5px !important;
}