/* NavBar.css */

.NavBarContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: #24345a;
  color: white;
  padding: 5px;
}

.NavBarContainer a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;

  
  transition: transform 0.3s, color 0.3s, box-shadow 0.3s, border-radius 0.3s, padding 0.3s; /* Adicionado transição para transformação, cor, sombreamento, borda arredondada e padding */
}

.NavBarContainer a:hover {
  background-color: #acbbd4; /* Cor de fundo ao passar o mouse sobre o link */
  color: #24345a; /* Cor do texto ao passar o mouse sobre o link */
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona sombreamento ao passar o mouse sobre o link */
  border-radius: 8px; /* Adiciona borda arredondada ao passar o mouse sobre o link */
  padding: 5px; /* Aumenta o padding ao passar o mouse sobre o link */
}

.NavBarContainer .dropdown-toggle {
  background-color: #acbbd4 !important;
  border-color: #acbbd4 !important;
  color: #24345a !important;
  font-size: 12px;
  font-weight: bold;
}

.dropdown-toggle {
  margin-top: -10px !important;
}

.NavBarContainer .dropdown-toggle:hover {
  background-color: #acbbd4; /* Cor de fundo ao passar o mouse sobre o dropdown-toggle */
}