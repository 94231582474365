/*Rodapé de paginação*/
div .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    background-color: #dae2ee !important;
    height: 80px !important;
    font-size: 10px !important;
}

/*Cabeçalho de busca*/
div .p-datatable .p-datatable-header {
    border-color: #24345a;
    background-color: #24345a !important;
    font-size: 10px !important;
}

div .p-datatable .p-datatable-footer {
    background-color: #24345a;
    color: white;
}

.p-inputtext::placeholder,
.p-input-icon-left .pi>.pi-search {
    color: white !important;
}

/* .Paginator,
.p-datatable .p-datatable-header,
.p-datatable .p-datatable-footer,
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr > th,
.p-datatable .p-datatable-thead > tr > th {
    border: 1px solid white !important;

} */

.PslDataTable {
    font-family: 'Courier New', Courier, monospace !important;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    width: 2rem;
    height: 8px;
    color: #6b7280;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}