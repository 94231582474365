.Paciente {
    display: flex !important;
    justify-content: space-between !important
}

.Paciente .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
}

.Body {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
}

.Body .NovoPaciente {
    justify-content: left;
    display: flex !important;
    margin-bottom: 12px;
    /* justify-content: space-around !important */
}

.ActionItens {
    display: flex !important;
    justify-content: center !important
}

.FilterItens {
    width: 100%;
    display: flex !important;
    justify-content: left !important
}

.FilterItens label {
    margin-right: 5px !important;
}

.DadosPsicologo {
    margin-top: 8px !important;
    color: #24345a;
    width: 100%;
    display: flex !important;
    justify-content: left !important
}

.FilterItensTitle label {
    margin-top: 20px;
    border-bottom: 2px solid #24345a;
    border-bottom-right-radius: 45px;
    width: 100%;
    display: flex !important;
    justify-content: left !important
}

.FiltroPsicologo {
    font-size: 10px !important;
}