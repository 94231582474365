body {
    font-family: 'Sans Serif', sans-serif;
    background-color: #fff !important;
    color: #000 !important;
    text-align: center !important;
}

label {
    font-size: 10px;
}

div .p-panel .p-panel-header {
    background: #24345a;
    color: #fff;
}

.p-dialog .p-dialog-header {
    background: #24345a;
}

.p-panel .p-panel-content {
    background: #acbbd4;
}

.p-inputtext {
    font-size: 10px !important;
    background: #7988ac;
    color: #fff;
    height: 50% !important;
}

.p-dropdown {
    height: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-dropdown-item {
    font-size: 10px;
}

.p-dropdown-label {
    font-size: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    background: #24345a;
    color: #fff
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    background: #1e1f26;
    color: #fff
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #fff;
}

.Footer {
    margin-top: 20px;
    display: flex !important;
    justify-content: space-between !important
}

.LogoHeader {
    width: 40px;
    margin-right: 20px;
}

.p-password-input {
    padding-bottom: 6px !important;
}