
/*TAMANHO HEADER*/
.page-header,
.page-header-space {
    height: 80px;
}

/*TAMANHO FOOTER*/
.page-footer,
.page-footer-space {
    height: 80px;
}

.page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    align-items: center;
    font-size: 10px;
    padding: 10px 0;
    margin: 2px 0;
}

.page-header {
    position: fixed;
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.conteudo {
    page-break-after: always;
}

.BodyData {
    /* margin-top: 15px; */
    margin-right: 20px;
    margin-left: 25px;
}

.PanelHeader {
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: #24345a;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BodyData .p-inputtext {
    color: #24345a !important;
    font-size: 13px !important;
    font-weight: normal;
}

@media print {
    .conteudo {
        padding: 20px;
    }

    .p-panel .p-panel-content {
        background: white;
        border: 0;
    }

    .p-inputtext {
        background: white;
        color: #24345a !important;
        border: 0;
        font-size: 13px !important;
        font-weight: normal;
    }

    .p-disabled {
        color: #24345a !important;
        opacity: 25 !important;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    table {
        width: 100%;
    }

    button {
        display: none;
    }

    body {
        margin: 0;
    }
}