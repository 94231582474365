.Relatorios {
    display: flex !important;
    justify-content: space-between !important
}

.Relatorios .Title {
    font-family: 'Vast Shadow', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    color: #5685a5;
    border-bottom: 0.5rem solid #24345a;
    border-bottom-right-radius: 45px;
    text-align: left;
}

.BodyRelatorios {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
}

.RelatoriosActionItens{
    display: flex !important;
    justify-content: center !important
}