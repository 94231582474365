.PacienteList {
  font-size: 10px !important;
}

.EmitirVpp {
  font-size: 10px !important;
  color: red !important;
}

.Sessoes {
  width: 300px !important;
}

.SessoesListWrapper {
  display: flex;
}

.SessoesList {
  width: 100%;
  /* A tabela ocupa toda a largura disponível */
  border-collapse: collapse;
  /* Mescla as bordas das células */
}

.SessoesList td {
  border: 1px solid #ddd;
  /* Adiciona bordas às células */
}

.SessoesList td:first-child {
  width: 50%;
  /* Define a largura da primeira coluna */
}

.SessoesList td:last-child {
  width: 50%;
  /* Define a largura da segunda coluna */
}